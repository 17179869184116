
<template>
  <div class='my-class'>
  </div>
</template>

<script>
export default {
  name: 'MyName',
  props: {},
  components: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {
    const path = this.$store.state.user.authority === 0 ? '/home' : '/admin'
    this.$router.push(path)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang='scss' scoped>

</style>
